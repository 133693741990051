import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { Box, Heading, Button, CheckBox, Select, Text, Layer, TextInput, TextArea } from "grommet";
import { firestore, auth } from "firebase/app";
import * as Icon from "grommet-icons";
import moment from "moment";
import NewRoomModal from "../../components/NewRoomModal";
import defImg from "../../assets/img/default.jpg";
import axios from "axios";
import BG from "../../assets/img/bgn.jpg";
import randomWords from "random-words";

import Table from "../../components/Table";
import UserContext from "../../context/UserContext";
import Loader from "react-loader-spinner";


const Admin = () => {
	const { authUser } = useContext(UserContext);
	var user = auth().currentUser;

	const initForm = {
		title: "",
		duration: "",
		private: true,
	};
	const [rooms, setRooms] = useState([]);
	const [privateRooms, setPrivateRooms] = useState([]);
	const [roomsPending, setRoomsPending] = useState([]);
	const [roomsPrivateInfo, setRoomsPrivateInfo] = useState([]);
	const [roomsFinished, setRoomsFinished] = useState([]);
	const [support, setSupport] = useState([]);
	const [newRoomForm, setNewRoomForm] = useState({});
	const [newRoomModal, setNewRoomModal] = useState(false);
	const [changePassModal, setChangePassModal] = useState(false);
	const [infoModal, setInfoModal] = useState(false);
	const [approveModal, setApproveModal] = useState(false);
	const [rejectModal, setRejectModal] = useState(false);
	const [roomSelected, setRoomSelected] = useState({});
	const [expressRoom, setExpressRoom] = useState(false);
	const [expressRoomForm, setExpressRoomForm] = useState(initForm);
	const [creatingRoom, setCreatingRoom] = useState(false);
	const [tab, setTab] = useState(2);
	const [linkC, setLinkC] = useState(null);
	const [linkA, setLinkA] = useState(null);
	const [newPass, setNewPass] = useState({
		adminpin: "",
		password: "",
	});

	useEffect(() => {
		const listenerRequests = firestore()
			.collection("roomrequests")
			.orderBy("createdAt", "asc")
			.onSnapshot((res) => {
				setRoomsPending(res.docs.map((room) => ({ ...room.data(), id: room.id })));
			});
		const listener = firestore()
			.collection("rooms")
			.where("private", "==", false)
			.where("ended", "==", false)
			// .where('ends', '>', new Date(Date.now()))
			.orderBy("ends", "desc")
			.onSnapshot((res) => {
				setRooms(res.docs.map((room) => ({ ...room.data(), id: room.id })));
			});
		const listenerPrivate = firestore()
			.collection("rooms")
			.where("private", "==", true)
			.where("ended", "==", false)
			// .where('ends', '>', new Date(Date.now()))
			.orderBy("ends", "desc")
			.onSnapshot((res) => {
				setPrivateRooms(res.docs.map((room) => ({ ...room.data(), id: room.id })));
			});
		const listenerFinished = firestore()
			.collection("rooms")
			.where("ended", "==", true)
			.orderBy("ends", "desc")
			.onSnapshot((res) => {
				setRoomsFinished(res.docs.map((room) => ({ ...room.data(), id: room.id })));
			});
		const listenerPrivateInfo = firestore()
			.collection("roomsprivateinfo")
			.onSnapshot((res) => {
				setRoomsPrivateInfo(res.docs.map((room) => ({ ...room.data(), id: room.id })));
			});
		const listenerIncidencias = firestore()
			.collection("support")
			.where("fixed", "==", false)
			.orderBy("createdAt", "asc")
			.onSnapshot((res) => {
				setSupport(res.docs.map((room) => ({ ...room.data(), id: room.id })));
			});
		return () => {
			listener();
			listenerRequests();
			listenerPrivate();
			listenerFinished();
			listenerPrivateInfo();
			listenerIncidencias();
		};
	}, []);

	const reject = () => {
		const variables = {
			reason: roomSelected.reason,
			title: roomSelected.title,
			email: roomSelected.email,
		};
		axios
			.post(process.env.REACT_APP_API_URL + "/rejectRoom", {
				method: "POST",
				body: JSON.stringify(variables),
			})
			.then((response) => {
				console.log("QUE PASO?", response);
				firestore()
					.collection("roomrequests")
					.doc(roomSelected.id)
					.delete()
					.then((res) => {
						setRejectModal(false);
					})
					.catch((err) => {
						alert("Ha ocurrido un error al rechazar esta solicitud, inténtelo en unos momentos de nuevo.");
						setRejectModal(false);
					});
			})
			.catch((error) => {
				alert("La sala se ha rechazado con éxito pero ha ocurrido un error la notificación al usuario, por favor ponte en contacto con el a través de su email.");
				setRejectModal(false);
			});
	};
	const deleteRoom = (id) => {
		firestore().collection("rooms").doc(id).delete();
		firestore().collection("roomsprivateinfo").doc(id).delete();
	};

	const closeRoom = (id) => {
		firestore().collection("rooms").doc(id).update({
			live: false,
			ended: true,
		});
	};

	const reopenRoom = (id) => {
		firestore().collection("rooms").doc(id).update({
			live: false,
			ended: false,
		});
	};

	const hideRoom = (id) => {
		firestore().collection("rooms").doc(id).update({
			private: true,
		});
	};

	const fixSupport = (id) => {
		firestore().collection("support").doc(id).update({
			fixed: true,
		});
	};

	const makePublicRoom = (id) => {
		firestore().collection("rooms").doc(id).update({
			ended: false,
		});
	};

	const changePass = (id) => {
		firestore()
			.collection("roomsprivateinfo")
			.doc(id)
			.update({
				password: parseInt(newPass.password),
				adminpin: parseInt(newPass.adminpin),
			})
			.then((res) => {
				setChangePassModal(false);
				setRoomSelected({});
			});
	};

	const createRoom = () => {
		setCreatingRoom(true);
		const variables = {
			title: `Sala Rápida ${moment().format("DD MMMM YYYY - hh:mm")}`,
			description: "",
			participants: 20,
			starts: moment().toDate(),
			zoom: "",
			ends: moment().add(60, "minutes").toDate(),
			private: true,
			image: defImg,
			live: false,
			protected: true,
			ended: false,
			activeusers: 0,
			waitingRoom: [],
			banned: [],
			granted: [],
		};

		const adminpin = Math.floor(100000 + Math.random() * 900000);
		const password = Math.floor(Math.random() * 899999 + 100000);
		const privatevariables = {
			adminpin,
			password,
			zoompassword: "",
			zoom: "",
			protected: true,
			contact: user.displayName || process.env.REACT_APP_ORGANIZATION_NAME,
			description: "",
			participants: 20,
			organization: process.env.REACT_APP_ORGANIZATION_NAME,
			email: user.email || "",
			title: `Sala Rápida ${moment().format("DD MMMM YYYY - hh:mm")}`,
			starts: moment().toDate(),
			ends: moment().add(60, "minutes").toDate(),
		};

		firestore()
			.collection("roomsprivateinfo")
			.add(privatevariables)
			.then((res) => {
				console.log(res);
				const salaID = res.id;
				firestore()
					.collection("rooms")
					.doc(salaID)
					.set(variables)
					.then((res) => {
						console.log(res);
						firestore()
							.collection("roomrequests")
							.doc(salaID)
							.delete()
							.then(async (res) => {
								navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL}/sala/${salaID}?s=${password}`).then(() => {
									setCreatingRoom(false);
									window.open(`${process.env.REACT_APP_PUBLIC_URL}/host/${salaID}?s=${adminpin}`, "_blank");
								});
								// setExpressRoomForm(initForm)
								// setExpressRoom(false)
							})
							.catch((err) => {
								alert("Ha ocurrido un error al eliminar la solicitud de sala pero la sala se ha creado con éxito.");
							});
					})
					.catch((err) => {
						console.log(err);
						alert("Ha ocurrido un error al aceptar la solicitud de sala.");
					});
			})
			.catch((err) => {
				alert("Ha ocurrido un error al aceptar la solicitud de sala. No se han podido generar credenciales de seguridad");
			});
	};

	const approveRoom = () => {
		const variables = {
			title: roomSelected.title || "",
			description: roomSelected.description || "",
			participants: parseInt(roomSelected.participants) || "",
			starts: roomSelected.starts || "",
			zoom: roomSelected.zoom || "",
			ends: roomSelected.ends || "",
			private: roomSelected.private || "",
			image: roomSelected.image || defImg,
			live: false,
			protected: true,
			ended: false,
			activeusers: 0,
			waitingRoom: [],
			banned: [],
			granted: [],
			private: false,
		};

		const privatevariables = {
			adminpin: Math.floor(100000 + Math.random() * 900000),
			password: Math.floor(Math.random() * 899999 + 100000),
			zoompassword: roomSelected.zoompassword || "",
			zoom: roomSelected.zoom || "",
			protected: true,
			contact: roomSelected.contact || "",
			description: roomSelected.description || "",
			participants: parseInt(roomSelected.participants) || "",
			organization: roomSelected.organization || "",
			email: roomSelected.email || "",
			title: roomSelected.title || "",
			ends: roomSelected.ends || "",
			starts: roomSelected.starts || "",
		};
		firestore()
			.collection("roomsprivateinfo")
			.doc(roomSelected.id)
			.set(privatevariables)
			.then((res) => {
				firestore()
					.collection("rooms")
					.doc(roomSelected.id)
					.set(variables)
					.then((res) => {
						console.log(res);
						firestore()
							.collection("roomrequests")
							.doc(roomSelected.id)
							.delete()
							.then((res) => {
								setApproveModal(false);
								setRoomSelected({});
							})
							.catch((err) => {
								alert("Ha ocurrido un error al eliminar la solicitud de sala pero la sala se ha creado con éxito.");
							});
					})
					.catch((err) => {
						console.log(err);
						alert("Ha ocurrido un error al aceptar la solicitud de sala.");
					});
			})
			.catch((err) => {
				alert("Ha ocurrido un error al aceptar la solicitud de sala. No se han podido generar credenciales de seguridad");
			});
	};

	const rejectRoom = (data) => {
		firestore()
			.collection("roomrequests")
			.doc(data.id)
			.delete()
			.then((res) => console.log(res));
	};

	const columnsR = useMemo(() => [
		{ id: "title", Header: "Nombre de la sala", Filter: "", accessor: "title" },
		// { id: "hide", Header: "Ocultar", size: "80px", align: "center", render: datum => <Button hoverIndicator icon={<Icon.Hide />} /> },
		{
			id: "changepass",
			Header: "Cambiar Contraseña",
			width: 50,
			Filter: "",
			align: "center",
			accessor: (datum, rowIndex) => (
				<Button
					hoverIndicator
					onClick={() => {
						setChangePassModal(true);
						setNewPass({
							adminpin: Math.floor(100000 + Math.random() * 900000),
							password: Math.floor(Math.random() * 899999 + 100000),
						});
						setRoomSelected(datum);
					}}
					icon={<Icon.License />}
				/>
			),
		},
		{
			id: "link",
			Header: "Enlace Publico",
			Filter: "",
			align: "center",
			width: 50,
			accessor: (datum, rowIndex) => (
				<Button
					hoverIndicator
					onClick={() => {
						setLinkC(datum.id);
						navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL}/sala/${datum.id}?s=${roomsPrivateInfo.filter((e) => e.id === datum.id)[0].password}`);
						setTimeout(() => {
							setLinkC(null);
						}, 2000);
					}}
					focusIndicator={false}
					icon={linkC === datum.id ? <Icon.StatusGood color="status-ok" /> : <Icon.Link />}
				/>
			),
		},
		{
			id: "linkadmin",
			Header: "Enlace Moderador",
			Filter: "",
			width: 50,
			align: "center",
			accessor: (datum, rowIndex) => (
				<Button
					hoverIndicator
					onClick={() => {
						setLinkA(datum.id);
						navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL}/host/${datum.id}?s=${roomsPrivateInfo.filter((e) => e.id === datum.id)[0].adminpin}`);
						setTimeout(() => {
							setLinkA(null);
						}, 2000);
					}}
					icon={linkA === datum.id ? <Icon.StatusGood color="status-ok" /> : <Icon.Link />}
				/>
			),
		},
		{
			id: "close",
			Header: "Cerrar Sala",
			Filter: "",
			width: 50,
			align: "center",
			accessor: (datum, rowIndex) => <Button hoverIndicator onClick={() => closeRoom(datum.id)} icon={<Icon.Lock color="status-critical" />} />,
		},
	]);

	const columnsF = useMemo(() => [
		{ id: "title", Header: "Nombre de la sala", accessor: "title" },
		{ id: "participants", Filter: "", Header: "Participantes", width: 80, align: "center", accessor: (datum, rowIndex) => datum.granted.length },
		{ id: "duration", Filter: "", Header: "Duración", width: 80, align: "center", accessor: (datum, rowIndex) => <Text>{datum.session ? Math.round(datum.session.reduce((ac, curr) => ac + curr.duration, 0) / 60, 0) : 0} min</Text> },
		{ id: "show", Filter: "", Header: "Reabrir Sala", width: 80, align: "center", accessor: (datum, rowIndex) => <Button onClick={() => reopenRoom(datum.id)} hoverIndicator icon={<Icon.Unlock />} /> },
		{
			id: "link",
			Filter: "",
			Header: "Publico",
			width: 80,
			align: "center",
			accessor: (datum, rowIndex) => (
				<Button
					hoverIndicator
					hoverIndicator
					onClick={() => {
						setLinkC(datum.id);
						navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL}/sala/${datum.id}?s=${roomsPrivateInfo.filter((e) => e.id === datum.id)[0].password}`);
						setTimeout(() => {
							setLinkC(null);
						}, 2000);
					}}
					focusIndicator={false}
					icon={linkC === datum.id ? <Icon.StatusGood color="status-ok" /> : <Icon.Link />}
				/>
			),
		},
		{
			id: "linkadmin",
			Filter: "",
			Header: "Moderador",
			width: 80,
			align: "center",
			accessor: (datum, rowIndex) => (
				<Button
					hoverIndicator
					hoverIndicator
					onClick={() => {
						setLinkA(datum.id);
						navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL}/host/${datum.id}?s=${roomsPrivateInfo.filter((e) => e.id === datum.id)[0].password}`);
						setTimeout(() => {
							setLinkA(null);
						}, 2000);
					}}
					focusIndicator={false}
					icon={linkA === datum.id ? <Icon.StatusGood color="status-ok" /> : <Icon.Link />}
				/>
			),
		},
		{
			id: "close",
			Header: "Borrar Sala",
			Filter: "",
			width: 80,
			align: "center",
			accessor: (datum, rowIndex) => <Button hoverIndicator onClick={() => deleteRoom(datum.id)} icon={<Icon.Trash color="status-critical" />} />,
		},
	]);

	const columnsRP = useMemo(
		() => [
			{
				id: "title",
				Header: "Sala Solicitada",
				// size: "medium",
				width: 300,
				Filter: "",
				accessor: (datum, rowIndex) => {
					// return (datum.title)
					return (
						<Box align="start" gap="small">
							<Text weight="bold">{datum.title}</Text>

							<Box pad={{ horizontal: "small", vertical: "5px" }} background="light-2" round="5px" gap="5px" direction="row">
								<Text>{datum.createdAt ? moment(datum.createdAt.toDate()).format("DD MMM YYYY") : ""}</Text>
								<Text>
									{" "}
									de {datum.starts ? moment(datum.starts.toDate()).format("hh:mm") : ""} a {datum.ends ? moment(datum.ends.toDate()).format("hh:mm") : ""}
								</Text>
							</Box>
						</Box>
					);
				},
			},
			{ id: "organization", Header: "Club/Organización", accessor: "organization" },
			// { id: "contact", header: "Contacto", size: "medium" },
			{ id: "participants", Filter: "", align: "right", Header: "Participantes", align: "center", accessor: "participants" },
			// {
			//     id: "createdAt", header: "Fecha", size: "medium", align:"center",
			//     render: datum => {
			//         return (
			//             <Box align="center">
			//                 <Text weight="bold">{datum.createdAt ? moment(datum.createdAt.toDate()).format("DD MMM YYYY") : ""}</Text>
			//                 <Box pad={{ horizontal: "small", vertical: "5px" }} background="light-2" round="5px">
			//                     <Text>{datum.starts ? moment(datum.starts.toDate()).format("hh:mm") : ""} a {datum.ends ? moment(datum.ends.toDate()).format("hh:mm") : ""}</Text>
			//                 </Box>
			//             </Box>
			//         )
			//     }
			// },
			{
				id: "details",
				Filter: "",
				align: "right",
				Header: "Detalles",
				width: 70,
				align: "center",
				accessor: (datum) => (
					<Button
						hoverIndicator
						onClick={() => {
							setRoomSelected(datum);
							setInfoModal(true);
						}}
						icon={<Icon.CircleInformation />}
					/>
				),
			},
			{
				id: "approve",
				Filter: "",
				Header: "Aprobar",
				width: 70,
				align: "center",
				accessor: (datum) => (
					<Button
						hoverIndicator
						onClick={() => {
							setRoomSelected(datum);
							setApproveModal(true);
						}}
						icon={<Icon.Validate color="status-ok" />}
					/>
				),
			},
			{
				id: "reject",
				Filter: "",
				Header: "Denegar",
				width: 70,
				align: "center",
				accessor: (datum) => (
					<Button
						hoverIndicator
						onClick={() => {
							setRoomSelected(datum);
							setRejectModal(true);
						}}
						icon={<Icon.Clear color="status-critical" />}
					/>
				),
			},
		],
		[]
	);

	const columnsSP = useMemo(
		() => [
			{
				id: "email",
				Header: "Email",
				// size: "medium",
				width: 200,
				Filter: "",
				accessor: "email",
			},
			{ id: "createdAt", Filter: "", Header: "Fecha", accessor: (datum) => (datum.createdAt ? moment(datum.createdAt.toDate()).format("DD MMM YYYY - hh:mm") : "") },
			{ id: "type", Filter: "", Header: "Tipo de Solicitud", align: "left", accessor: "type" },
			{ id: "notes", width: 300, Filter: "", Header: "Incidencia", align: "left", accessor: "notes" },
			{
				id: "fix",
				Filter: "",
				Header: "Solicionada",
				width: 80,
				align: "center",
				accessor: (datum) => (
					<Button
						hoverIndicator
						onClick={() => {
							fixSupport(datum.id);
						}}
						icon={<Icon.Checkmark color="status-ok" />}
					/>
				),
			},
		],
		[]
	);

	const dd = Array.from(Array(100).keys()).map((r) => ({
		title: randomWords({ exactly: 5, join: " " }),
		participants: Math.round(Math.random() * 100),
		organization: randomWords({ exactly: 2, join: " " }),
		starts: moment(),
		ends: moment().add(Math.random() * 5, "hours"),
	}));
	// [
	//     {title: randomWords({ exactly: 5, join: ' ' }), participants: Math.random()},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},{title: "asdasd"},
	// ]

	return (
		<Box flex="grow" align="center">
			<Box fill="horizontal" pad={{ top: "50px" }} align="center" background={`url(${BG})`} height="small">
				<Heading level="2" margin="none" color="white">
					Gestión de Salas
				</Heading>
			</Box>

			<Box direction="row" pad="medium" flex="grow" fill="horizontal" style={{ marginTop: -80 }}>
				<Box background="#151515" elevation="large" round={{ corner: "left", size: "10px" }} pad="medium">
					<Box gap="medium" align="start">
						<Box justify="between" focusIndicator={false} gap="small" onClick={() => setTab(1)} style={{ opacity: tab === 1 ? 1 : .5 }} direction="row">
                            <Text weight="bold">Pendientes</Text>
                            {roomsPending.length > 0 && <Box align="center" justify="center" width="25px" height="25px" background="status-critical" color="white" round="15px">
                                <Text size="small" weight="bold">{roomsPending.length}</Text>
                            </Box>}
                        </Box>

						<Box focusIndicator={false} gap="small" onClick={() => setTab(2)} style={{ opacity: tab === 2 ? 1 : 0.5 }} direction="row">
							<Text weight="bold">Salas Públicas</Text>
						</Box>

						<Box focusIndicator={false} gap="small" onClick={() => setTab(3)} style={{ opacity: tab === 3 ? 1 : 0.5 }} direction="row">
							<Text weight="bold">Salas Privadas</Text>
						</Box>

						<Box focusIndicator={false} gap="small" onClick={() => setTab(4)} style={{ opacity: tab === 4 ? 1 : 0.5 }} direction="row">
							<Text weight="bold">Salas Cerradas</Text>
						</Box>

						<Box justify="between" focusIndicator={false} gap="small" onClick={() => setTab(5)} style={{ opacity: tab === 5 ? 1 : 0.5 }} direction="row">
							<Text weight="bold">Soporte</Text>
							{support.length > 0 && (
								<Box align="center" justify="center" width="25px" height="25px" background="status-critical" color="white" round="15px">
									<Text size="small" weight="bold">
										{support.length}
									</Text>
								</Box>
							)}
						</Box>
						<Box focusIndicator={false} gap="small" onClick={() => setTab(6)} style={{ opacity: tab === 6 ? 1 : 0.5 }} direction="row">
							<Text weight="bold">Usuarios</Text>
						</Box>

						<Button
							icon={<Icon.Add />}
							primary
							color="white"
							label="Nueva Sala"
							onClick={() => {
								setNewRoomModal(true);
							}}
						/>

						{creatingRoom ? (
							<Loader type="Puff" color="white" height={45} width={45} />
						) : (
							<Button
								icon={<Icon.Clock />}
								primary
								color="white"
								label="Sala Rápida"
								onClick={() => {
									createRoom();
								}}
							/>
						)}
					</Box>
				</Box>

				<Box flex background="white" elevation="medium" round={{ corner: "right", size: "10px" }}>
					<Box pad="medium">
						{tab === 1 && (
							<Box>
								<Table columns={columnsRP} data={roomsPending} />
								{/* <DataTable
                                columns={columnsRP}
                                data={roomsPending}
                                step={10}
                                border={{ body: "bottom" }}
                                pad={{ vertical: "medium", horizontal: "small" }}
                            /> */}
							</Box>
						)}

						{tab === 2 && (
							<Box>
								<Table columns={columnsR} data={rooms} />
								{/* <DataTable
                                columns={columnsR}
                                data={rooms}
                                sortable={true}
                                border={{ body: "bottom" }}
                                pad={{ vertical: "medium", horizontal: "small" }}
                            /> */}
							</Box>
						)}

						{tab === 3 && (
							<Box>
								<Table columns={columnsR} data={privateRooms} />
								{/* <DataTable
                                columns={columnsR}
                                data={privateRooms}
                                step={10}
                                border={{ body: "bottom" }}
                                pad={{ vertical: "medium", horizontal: "small" }}
                            /> */}
							</Box>
						)}

						{tab === 4 && (
							<Box>
								<Table columns={columnsF} data={roomsFinished} />
							</Box>
						)}

						{tab === 5 && (
							<Box>
								<Table columns={columnsSP} data={support} />
							</Box>
						)}
					</Box>
				</Box>
			</Box>

			{newRoomModal && (
				<NewRoomModal
					closeCallback={() => {
						setNewRoomModal(false);
						setNewRoomForm({});
					}}
					data={newRoomForm}
				/>
			)}

			{infoModal && (
				<Layer
					onClickOutside={() => {
						setInfoModal(false);
						setRoomSelected({});
					}}
					onEsc={() => {
						setInfoModal(false);
						setRoomSelected({});
					}}>
					<Box pad="medium" gap="medium" width="large">
						<Box direction="row" justify="between" align="center">
							<Box flex>
								<Heading level="3" margin="none">
									Detalles de la solicitud
								</Heading>
							</Box>
							<Button
								hoverIndicator
								onClick={() => {
									setInfoModal(false);
									setRoomSelected({});
								}}
								icon={<Icon.Close />}
							/>
						</Box>
						<Box gap="small">
							<Text size="small" color="gray">
								Solicitante
							</Text>
							<Text>{roomSelected.contact}</Text>
						</Box>

						<Box gap="small">
							<Text size="small" color="gray">
								Email del Solicitante
							</Text>
							<Text>{roomSelected.email}</Text>
						</Box>

						<Box gap="small">
							<Text size="small" color="gray">
								Club / Organización
							</Text>
							<Text>{roomSelected.organization}</Text>
						</Box>

						<Box gap="small">
							<Text size="small" color="gray">
								Número de Participantes
							</Text>
							<Text>{roomSelected.participants}</Text>
						</Box>

						<Box gap="small">
							<Text size="small" color="gray">
								Motivo de la Reunión
							</Text>
							<Text>{roomSelected.description}</Text>
						</Box>
					</Box>
				</Layer>
			)}

			{changePassModal && (
				<Layer
					onClickOutside={() => {
						setChangePassModal(false);
						setRoomSelected({});
					}}
					onEsc={() => {
						setChangePassModal(false);
						setRoomSelected({});
					}}>
					<Box pad="medium" gap="medium" width="large">
						<Heading level="3" margin="none">
							Cambiar contraseña de la sala {roomSelected.title}
						</Heading>

						<Box>
							<Box gap="5px">
								<Text size="small">Nuevo PIN de administrador</Text>
								<TextInput type="number" value={newPass.adminpin} onChange={(ev) => setNewPass({ ...newPass, adminpin: ev.target.value })} placeholder="Contraseña..." />
							</Box>

							<Box gap="5px">
								<Text size="small">Nuevo PIN de usuarios</Text>
								<TextInput type="number" value={newPass.password} onChange={(ev) => setNewPass({ ...newPass, password: ev.target.value })} placeholder="Contraseña..." />
							</Box>
						</Box>

						<Box direction="row" justify="between">
							<Button
								primary
								color="light-4"
								onClick={() => {
									setChangePassModal(false);
									setRoomSelected({});
								}}
								style={{ color: "white" }}
								label="Cancelar"
							/>
							<Button disabled={roomSelected.participants > 20 && roomSelected.zoom === ""} primary color="status-ok" onClick={() => changePass(roomSelected.id)} style={{ color: "white" }} label="Cambiar" />
						</Box>
					</Box>
				</Layer>
			)}

			{approveModal && (
				<Layer
					onClickOutside={() => {
						setApproveModal(false);
						setRoomSelected({});
					}}
					onEsc={() => {
						setApproveModal(false);
						setRoomSelected({});
					}}>
					<Box pad="medium" gap="medium" width="large">
						<Heading level="3" margin="none">
							¿Estás seguro que deseas aprobar la sala {roomSelected.title}?
						</Heading>

						<Box>
							<Box gap="5px">
								<Text size="small">Número máximo de participantes</Text>
								<TextInput type="number" value={roomSelected.participants} onChange={(ev) => setRoomSelected({ ...roomSelected, participants: ev.target.value })} placeholder="Participantes" />
							</Box>
							{roomSelected.participants > 20 && (
								<Box gap="small">
									<Box align="center" justify="center" gap="medium" direction="row" background="light-2" pad={{ horizontal: "medium", vertical: "small" }} round="5px">
										<Icon.Alert />
										<Text>El número de participantes supera los 20. Pega el enlace de una conferencia de Zoom o cualquier otro servicio.</Text>
									</Box>
									<Box>
										<Text size="small">Enlace de Zoom</Text>
										<Box direction="row" gap="small" align="center">
											<TextInput value={roomSelected.zoom} placeholder="Enlace" onChange={(ev) => setRoomSelected({ ...roomSelected, zoom: ev.target.value })} />
											<Button icon={<Icon.Link />} target="_blank" href={roomSelected.zoom && roomSelected.zoom.includes("http") ? roomSelected.zoom : `https://${roomSelected.zoom}`} />
										</Box>
									</Box>
									<Box>
										<Text size="small">Contraseña de Zoom</Text>
										<Box direction="row" gap="small" align="center">
											<TextInput value={roomSelected.zoompassword} placeholder="Contraseña de Zoom" onChange={(ev) => setRoomSelected({ ...roomSelected, zoompassword: ev.target.value })} />
										</Box>
									</Box>
								</Box>
							)}
						</Box>

						<Box direction="row" justify="between">
							<Button
								primary
								color="light-4"
								onClick={() => {
									setApproveModal(false);
									setRoomSelected({});
								}}
								style={{ color: "white" }}
								label="Cancelar"
							/>
							<Button disabled={roomSelected.participants > 20 && roomSelected.zoom === ""} primary color="status-ok" onClick={() => approveRoom()} style={{ color: "white" }} label="Aprobar" />
						</Box>
					</Box>
				</Layer>
			)}

			{rejectModal && (
				<Layer
					onClickOutside={() => {
						setRejectModal(false);
						setRoomSelected({});
					}}
					onEsc={() => {
						setRejectModal(false);
						setRoomSelected({});
					}}>
					<Box pad="medium" gap="medium" width="large">
						<Heading level="3" margin="none">
							¿Estás seguro que deseas rechazar la sala {roomSelected.title}?
						</Heading>

						<Box>
							<Box gap="5px">
								<Text size="small">Motivo por el que se rechaza la sala</Text>
								<Box>
									<TextArea style={{ minHeight: 150 }} type="number" value={roomSelected.reason} onChange={(ev) => setRoomSelected({ ...roomSelected, reason: ev.target.value })} placeholder="Motivo..." />
								</Box>
							</Box>
						</Box>

						<Box direction="row" justify="between">
							<Button
								primary
								color="light-4"
								onClick={() => {
									setRejectModal(false);
									setRoomSelected({});
								}}
								style={{ color: "white" }}
								label="Cancelar"
							/>
							<Button disabled={roomSelected.reason === ""} primary color="status-critical" onClick={() => reject()} style={{ color: "white" }} label="Rechazar" />
						</Box>
					</Box>
				</Layer>
			)}

			{expressRoom && (
				<Layer onClickOutside={() => setExpressRoom(false)} onEsc={() => setExpressRoom(false)}>
					<Box pad="medium" gap="medium" width="large">
						<Heading level="3" margin="none">
							Crear Sala
						</Heading>

						<Box gap="medium">
							<Box gap="5px">
								<Text size="small">Nombre de la Sala</Text>
								<Box>
									<TextInput value={expressRoomForm.title} onChange={(ev) => setExpressRoomForm({ ...expressRoomForm, title: ev.target.value })} placeholder="Nombre..." />
								</Box>
							</Box>
							<Box gap="5px">
								<Text size="small">Duración de la Sesión</Text>
								<Box>
									<Select options={[15, 30, 45, 60]} value={roomSelected.duration} onChange={({ option }) => setExpressRoomForm({ ...expressRoomForm, duration: option })} placeholder="Duración..." />
								</Box>
							</Box>
							<Box gap="5px">
								<Text size="small">Tipo de Reunión</Text>
								<CheckBox
									toggle={true}
									label={`${expressRoomForm.private ? "Privada: Esta reunión no aparecerá en el portal" : "Pública: Esta reunión será visible públicamente en el portal"}`}
									checked={expressRoomForm.private}
									onChange={(ev) => setExpressRoomForm({ ...expressRoomForm, private: ev.target.checked })}
								/>
							</Box>
						</Box>

						<Box direction="row" justify="between">
							<Button
								primary
								color="light-4"
								onClick={() => {
									setExpressRoom(false);
									setExpressRoomForm({});
								}}
								style={{ color: "white" }}
								label="Cancelar"
							/>
							<Button disabled={expressRoomForm.title === ""} primary color="brand" onClick={() => createRoom()} style={{ color: "white" }} label="Crear Sala" />
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default Admin;
