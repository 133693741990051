import React, { useEffect, useState, useMemo } from "react";
import { Grommet, Box, Layer, Text, Heading, Button } from "grommet";
import * as Icon from "grommet-icons";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./containers/Admin/Footer";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import Home from "./containers/Home";
import Room from "./containers/Room";
import Admin from "./containers/Admin/Admin";
import Login from "./containers/Login";

import UserContext from "./context/UserContext";
import Host from "./containers/Host";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import PrivateRoute from "./components/PrivateRoute";
import Logout from "./containers/Logout";
import MenuContext from "./context/MenuContext";
import { isIE } from "react-device-detect";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

const theme = {
	global: {
		colors: {
			brand: process.env.REACT_APP_BRAND_COLOR,
			focus: "gainsboro",
		},
		font: {
			family: "Montserrat",
			size: "14px",
			height: "20px",
		},
	},
	text: {
		medium: {
			size: "16px",
		},
	},
	button: {
		border: {
			radius: "5px",
			width: "0px",
		},
		padding: {
			vertical: "5px",
		},
	},
};

firebase.initializeApp(firebaseConfig);

const App = () => {
	const [userID, setUserID] = useState(null);

	const [authUser, setAuthUser] = useState(null);
	const [authed, setAuthed] = useState(null);
	const [showMenu, setShowMenu] = useState(true);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const recoverUserID = async () => {
			const name = await localStorage.getItem("userName");
			const team = await localStorage.getItem("userTeam");
			if (name || team) {
				encodeUserID(name + "," + team);
			}
		};

		const listener = firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				setLoading(false);
				setAuthed(true);
			} else {
				setLoading(false);
				setAuthed(false);
			}
		});
		recoverUserID();

		return () => listener();
	}, []);

	const encodeUserID = (st) => {
		setUserID(btoa(st));
	};

	const menuFunctions = useMemo(
		() => ({
			showHide: async (state) => {
				setShowMenu(state);
			},
		}),
		[]
	);

	return (
		<Grommet style={{ minHeight: "-webkit-fill-available", height: "100%", display: "flex", flexDirection: "column" }} theme={theme} background="#F5F6F9">
			<UserContext.Provider value={{ userID: userID, authUser: authUser, setAuthUser: setAuthUser, setUserID: setUserID }}>
				<MenuContext.Provider value={{ showMenu: showMenu, menuFunctions: menuFunctions }}>
					<Router>
						{isIE && (
							<Box background="dark-2" pad="small" align="center">
								<Text textAlign="center">El navegador que estas usando puede no ser compatible con el sistema. Te recomendamos usar Google Chrome para una mejor experiencia</Text>
							</Box>
						)}
						<Header userID={userID} changeUserCallback={(ev) => setUserID(ev)} showMenu={showMenu} authed={authed} />
						{/* <Box style={{ minHeight: "calc(100vh - 80px - 50px)" }}> */}
						<Box flex="grow">
							{loading ? (
								<Layer animate={false} plain full={true}>
									<Box fill background="rgba(0,0,0,.6)" align="center" justify="center">
										<Loader type="Puff" color="white" height={100} width={100} />
									</Box>
								</Layer>
							) : (
								<Switch>
									{/* <PrivateRoute exact path="/" component={Dashboard} />*/}
									{/* <Route exact path="/login" component={Login} /> */}
									{/* <Route exact path="/logout" component={Logout} /> */}
									<PrivateRoute authed={authed} exact path="/admin" component={Admin} />
									<Route exact path="/host/:id" component={Host} />
									<Route exact path="/sala/:id" component={Room} />
									<Route exact path="/login" component={Login} />
									<Route exact path="/logout" component={Logout} />
									<Route exact path="/" component={Home} />
									{/* <Route path="" component={NotFound} /> */}

									{/* <Route path="/" component={Home} /> */}
								</Switch>
							)}
							{/* </Box> */}
						</Box>
						<Footer showMenu={showMenu} />
					</Router>
					{isIE && (
						<Layer>
							<Box elevation="medium" pad="medium" align="center" justify="center">
								<Heading level="3" textAlign="center">
									El navegador que estas usando no es compatible con esta plataforma.
								</Heading>
								<Text>Actualiza tu navegador por uno compatible de la siguiente lista para continuar</Text>
								<Box direction="row" gap="small" margin={{ vertical: "medium" }}>
									<Button href="https://www.google.com/intl/es_es/chrome/" icon={<Icon.Chrome />} label="Chrome" />
									<Button href="https://www.mozilla.org/es-ES/firefox/new/" icon={<Icon.Firefox />} label="Firefox" />
									<Button href="https://support.apple.com/downloads/safari" icon={<Icon.SafariOption />} label="Safari" />
									<Button href="https://www.microsoft.com/es-es/edge" icon={<Icon.Edge />} label="Edge" />
									<Button href="https://www.opera.com/es" icon={<Icon.Opera />} label="Opera" />
								</Box>
							</Box>
						</Layer>
					)}
				</MenuContext.Provider>
			</UserContext.Provider>
		</Grommet>
	);
};

export default App;
