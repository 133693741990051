import React, { useState, useEffect } from 'react';
import { Box, Image, Text, Header as HeaderGrommet, TextInput, Button, Layer, Heading } from 'grommet';
import * as Icon from 'grommet-icons'
import NewRoomRequest from './NewRoomRequest';
import { Link, useLocation } from 'react-router-dom';

const Header = ({ authed, userID, changeUserCallback, showMenu }) => {

    const [open, toogle] = useState(false)
    const [newRoomModal, setNewRoomModal] = useState(false)
    const [userName, setUserName] = useState("")
    const [userTeam, setUserTeam] = useState("")
    const [openUser, setOpenUser] = useState(false)
    const [tab, setTab] = useState(1)

    const location = useLocation()

    useEffect(() => {
        const recoverUserTeam = async () => {
            setUserName(atob(userID).split(",")[0])
            setUserTeam(atob(userID).split(",")[1])
        }

        userID && recoverUserTeam()
    }, [userID])



    const changeUserTeam = async () => {
        await localStorage.setItem("userName", userName)
        await localStorage.setItem("userTeam", userTeam)
        changeUserCallback(btoa(`${userName},${userTeam}`))
        setOpenUser(false)
    }


    return (
        <Box style={{ display: showMenu ? "flex" : "none" }} flex="grow" height={{ max: "80px" }}>

            <HeaderGrommet background={{ color: process.env.REACT_APP_HEADER_COLOR, position: "bottom" }} pad={{ horizontal: "medium" }} flex="grow" height="80px" fill="horizontal" justify="between" direction="row" elevation="medium">
                <a style={{ textDecoration: "none" }} href={process.env.REACT_APP_PUBLIC_URL}>
                    <Box height="90px" width="250px" pad="small">
                        <Image fit="contain" src={process.env.REACT_APP_LOGO} />
                    </Box>
                </a>
                <Box direction="row" align="center">
                    {/* <Link style={{ textDecoration: "none" }} to="/login">
                        <Text>Home</Text>
                    </Link> */}
                    <Button onClick={() => setOpenUser(true)} icon={<Icon.User />} hoverIndicator />
                    <Button onClick={() => toogle(true)} icon={<Icon.CircleQuestion />} hoverIndicator />
                    <Button onClick={() => setNewRoomModal(true)} icon={<Icon.Add />} hoverIndicator />
                    {authed && <Button href="/admin" icon={<Icon.ShieldSecurity />} hoverIndicator />}
                    {authed && <Button href="/logout" icon={<Icon.Logout />} hoverIndicator />}
                </Box>
            </HeaderGrommet>

            {open &&
                <Layer
                    onClickOutside={() => toogle(false)}
                    onEsc={() => toogle(false)}
                    responsive={false}
                >
                    <Box width="large">
                        <Button hoverIndicator onClick={() => toogle(false)} style={{ position: "absolute", top: 0, right: 0 }} icon={<Icon.Close />} />

                        {location.pathname.includes("admin") ?
                            <Box gap="medium" background="white" round="small" pad="medium" >
                                <Heading margin="none" level="3">¿Cómo gestionar las salas?</Heading>
                                <Box direction="row" gap="small">
                                    <Button primary={tab === 1} onClick={() => setTab(1)} label="Panel de Administración" />
                                    <Button primary={tab === 2} onClick={() => setTab(2)} label="Crear Salas" />
                                </Box>
                                <Box gap="small">
                                    {tab === 1 ?
                                        <video controlsList="nodownload" controls src="https://firebasestorage.googleapis.com/v0/b/novanetlive-main.appspot.com/o/tutorials%2Fadmintutorial.mp4?alt=media&token=2b4c9308-7ddc-4728-8490-c92325e108ee" width="100%" height="300px" />
                                        :
                                        <video controlsList="nodownload" controls src="https://firebasestorage.googleapis.com/v0/b/novanetlive-main.appspot.com/o/tutorials%2Fcreateroomtutorial.mp4?alt=media&token=a6f760db-4cba-4a8b-888e-5897c6e9bbd6" width="100%" height="300px" />
                                    }
                                </Box>
                                <Box gap="small" gap="medium">
                                    <Heading style={{ width: "100%" }} margin="none" level="4">O descarga la guia de uso</Heading>
                                    <Box direction="row">
                                        <Button target="_blank" href="https://firebasestorage.googleapis.com/v0/b/novanetlive-main.appspot.com/o/tutorials%2Fadminmanual.pdf?alt=media&token=40b685ff-9907-4219-81ab-ef88f6c27c8f" icon={<Icon.Download />} primary label="Descargar guía Adminstrador" />
                                    </Box>
                                </Box>
                            </Box>
                            :
                            <Box gap="medium" background="white" round="small" pad="medium" >
                                <Heading margin="none" level="3">¿Cómo conectarse a una sala?</Heading>
                                <Box gap="small">
                                    <video controlsList="nodownload" controls src="https://firebasestorage.googleapis.com/v0/b/novanetlive-main.appspot.com/o/tutorials%2Fusertutorial.mp4?alt=media&token=16212154-48df-4e3d-80fc-395ac4350f2a" width="100%" height="300px" />
                                </Box>

                                <Box gap="small" gap="medium">
                                    <Heading style={{ width: "100%" }} margin="none" level="4">O descarga la guia de uso</Heading>
                                    <Box direction="row">
                                        <Button target="_blank" href="https://firebasestorage.googleapis.com/v0/b/novanetlive-main.appspot.com/o/tutorials%2Fusermanual.pdf?alt=media&token=330e42e5-f15c-41f4-957e-28e86bf4815d" icon={<Icon.Download />} primary label="Descargar guía de usuario" />
                                    </Box>
                                </Box>
                            </Box>

                        }
                    </Box>
                </Layer>
            }

            {newRoomModal &&
                <NewRoomRequest
                    closeCallback={() => {
                        setNewRoomModal(false)
                    }} />
            }
            {openUser &&
                <Layer
                    onClickOutside={() => setOpenUser(false)}
                    onEsc={() => setOpenUser(false)}
                    responsive={false}
                >
                    <Box flex="grow" align="center" >
                        <Box style={{ zIndex: 2, width: "100%" }} pad="medium" margin={{ top: "-50px" }} width={{ max: "600px" }} gap="medium" background="white" elevation="medium" round="7px">
                            <Heading margin="none" level="3">Introduce tus datos</Heading>
                            <Text>Aquí podrás cambiar tu nombre e información que aparecerá en las reuniones.</Text>
                            <Box gap="small">
                                <Text size="small">Nombre y Apellidos</Text>
                                <TextInput style={{ fontSize: 16 }} placeholder="Tu nombre..." value={userName} onChange={(ev) => setUserName(ev.target.value)} />
                            </Box>

                            <Box gap="small">
                                <Text size="small">Club/Organización</Text>
                                <TextInput style={{ fontSize: 16 }} placeholder="Escribe aquí..." value={userTeam} onChange={(ev) => setUserTeam(ev.target.value)} />
                            </Box>

                            <Box align="center" direction="row" justify="between" gap="small">
                                <Button
                                    primary
                                    color="light-2"
                                    onClick={() => setOpenUser(false)}
                                    label={"Cancelar"}
                                />
                                <Button
                                    primary
                                    disabled={userName === "" || userTeam === ""}
                                    onClick={() => changeUserTeam()}
                                    label={"Modificar"}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Layer>
            }
        </Box>
    );
}

export default Header;